<template>
  <div class="container">
    <el-card class="password-card">
      <el-form :model="form" :rules="rules" ref="passwordForm" label-width="120px" @submit.native.prevent="submitForm">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" placeholder="请输入旧密码" show-password></el-input>
        </el-form-item>

        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" native-type="submit">修改密码</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>

import { editPwd } from './AuthInfo.js'

export default {
  name: "info",
  data: function () {
    const validateNewPassword = (rule, value, callback) => {
      if (value === this.form.oldPassword) {
        callback(new Error("新密码不能与旧密码相同"));
      } else {
        callback();
      }
    };

    return {
      form: {
        oldPassword: "",
        newPassword: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    submitForm() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          editPwd(this.form)
        } else {
          return false;
        }
      });
    },
  },

};
</script>

<style scoped>
.container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100vh; /* 使容器占满整个视口高度 */
  padding: 20px;
}

.password-card {
  width: 20%; /* 桌面浏览器的默认宽度 */
}

/* 媒体查询：针对手机浏览器 */
@media (max-width: 768px) {
  .password-card {
    width: 100%; /* 手机浏览器的宽度 */
  }
}
</style>