import store from "@/store";
import axios from "axios";
import msg from "../../util/msg";
import router from "@/router";

// export const authInfo = store.state.auth.authInfo;

export function checkPermission(targetPermission) {
  var authInfo = store.state.auth.authInfo;
//   console.log(authInfo);
  if (authInfo == null) {
    return false;
  }

  if (authInfo.permissions == null) {
    return false;
  }

  return authInfo.permissions.includes(targetPermission);
}


export function editPwd(param){
    axios.post("/auth/editPwd", param).then(data=>{
        msg.success("修改成功,即将重新登录");
        router.push("/");
    })
}